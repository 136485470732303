import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-22 13:56:50
 */

//获取表格数据，以及筛选
export function getGiftList(parameter){
    return axios({
        url: '/pfGift/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//礼物新增
export function giftAdd(parameter){
    return axios({
        url: '/pfGift/add',
        method: 'post',
        data: parameter
    })
}
//礼物编辑
export function giftEdit(parameter){
    return axios({
        url: '/pfGift/edit',
        method: 'post',
        data: parameter
    })
}
//礼物详情
export function giftDetail(parameter){
    return axios({
        url: '/pfGift/detail',
        method: 'post',
        params: parameter
    })
}
//礼物删除
export function giftDelete(parameter){
    return axios({
        url: '/pfGift/delete',
        method: 'post',
        data: parameter
    })
}